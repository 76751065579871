import React, { useState } from "react"
import styled from "styled-components"
import TextField from "@material-ui/core/TextField"
import { useTranslation, Trans } from "react-i18next"
import Grid from "@material-ui/core/Grid"
import FormLabel from "@material-ui/core/FormLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Button } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import FileLink from "../components/fileLink"
import axios from "axios"
import { useCountDown, useError } from "../hooks"
import { useNavigate } from "@reach/router"
import InputAdornment from "@material-ui/core/InputAdornment"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import Modal from "@material-ui/core/Modal"
import WarningIcon from "@material-ui/icons/Warning"
import CancelIcon from "@material-ui/icons/Cancel"
import CircularProgress from "@material-ui/core/CircularProgress"

const HOST = process.env.GATSBY_API_HOST
const API_KEY = process.env.GATSBY_API_KEY
const REF_CODE = process.env.GATSBY_REF_CODE

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ")
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(" ")
}

const SubmissionForm = props => {
  const { t, i18n } = useTranslation()
  const [formData, setFormData] = useState({
    otp: "",
    mobile: "",
    firstname: "",
    lastname: "",
    email: "",
    isOtpSent: false,
    agree1: false,
    agree2: process.env.GATSBY_DM_OPT_OUT === "true" ? true : undefined,
  })

  const [isWrongEmailFormat, setIsWrongEmailFormat] = useState(false)

  const [loading, setLoading] = useState({
    submit: false,
    verify: false,
    send: false,
  })

  const lang = i18n.language || "zh"
  const { hasError, error, clearError, setError } = useError()

  const { currentTime, resetCountDown, clearCountDown } = useCountDown(60)
  const navigate = useNavigate()

  const handleModalClose = () => {
    clearError()
  }

  const updateData = key => e => {
    let value = e.target.value
    if (key === "agree1") {
      value = e.target.checked
    }

    if (key === "agree2") {
      if (process.env.GATSBY_DM_OPT_OUT === "true") {
        value = !e.target.checked
      } else {
        value = value === "yes"
      }
    }

    if (key === "otp" && (value.length > 6 || !/^[0-9]*$/.test(value))) {
      return
    }

    if (key === "mobile" && (value.length > 8 || !/^[0-9]*$/.test(value))) {
      return
    }

    if (key === "firstname" || key === "lastname") {
      if (
        !/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]*$/u.test(
          value
        )
      ) {
        return
      }

      value = titleCase(value)
    }

    if (key === "email") {
      setIsWrongEmailFormat(
        !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value)
      )
      value = value.toLowerCase()
    }

    setFormData(formDataPrev => ({ ...formDataPrev, [key]: value }))
  }

  const isAllowedToSubmit = () => {
    const requiredField = [
      "firstname",
      "lastname",
      "title",
      "email",
      "mobile",
      "otp",
      "agree1",
    ]

    if (process.env.GATSBY_DM_OPT_OUT !== "true") {
      requiredField.push("agree2")
    }

    const isAllFulfilled = requiredField.every(
      key => typeof formData[key] !== "undefined" && formData[key].length !== 0
    )

    return (
      isAllFulfilled &&
      formData.agree1 &&
      !isWrongEmailFormat &&
      formData.isOtpValid
    )
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const url = "/user/register"
    const data = {
      firstName: formData.firstname,
      lastName: formData.lastname,
      title: formData.title,
      email: formData.email,
      mobile: formData.mobile,
      agreeDM: formData.agree2,
      otp: formData.otp,
      refCode: REF_CODE,
    }

    try {
      setLoading({ ...loading, submit: true })
      await axios({
        method: "post",
        url,
        baseURL: HOST,
        headers: { "x-api-key": API_KEY },
        data,
      })

      navigate("/thankyou", { replace: true })
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errorCode === 40001
      ) {
        navigate("/thankyouagain", { replace: true })
      } else {
        navigate("/sorry", { replace: true })
      }
    }
  }

  const handleOtpRequest = async () => {
    const url = "/sendOTP"
    const data = {
      mobile: formData.mobile,
      lang,
    }

    try {
      setLoading({ ...loading, send: true })
      await axios({
        method: "post",
        url,
        baseURL: HOST,
        headers: { "x-api-key": API_KEY },
        data,
      })

      setFormData(formDataPrev => ({ ...formDataPrev, isOtpSent: true }))
      resetCountDown()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorCode === 40005
      ) {
        setError(t("error.numberunique"))
      } else {
        setError(t("error.sendcode"))
      }
    } finally {
      setLoading({ ...loading, send: false })
    }
  }

  const handleOtpVerify = async () => {
    const url = "/validateOTP"
    const data = {
      mobile: formData.mobile,
      otp: formData.otp,
    }

    try {
      setLoading({ ...loading, verify: true })

      await axios({
        method: "post",
        url,
        baseURL: HOST,
        headers: { "x-api-key": API_KEY },
        data,
      })

      setFormData(formDataPrev => ({ ...formDataPrev, isOtpValid: true }))
      clearCountDown()
    } catch (error) {
      setError(t("error.verifycode"))
      setFormData({ ...formData, isOtpValid: false })
    } finally {
      setLoading({ ...loading, verify: false })
    }
  }

  return (
    <SubmissionFormWrapper {...props} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <TextField
            color="primary"
            fullWidth
            error={false}
            label={t("form.firstname")}
            onChange={updateData("firstname")}
            required
            value={formData.firstname}
            autoComplete={"off"}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label={t("form.lastname")}
            onChange={updateData("lastname")}
            required
            value={formData.lastname}
            autoComplete={"off"}
          />
        </Grid>
        <Grid item md={12} xs={12} style={{ marginTop: 30 }}>
          <FormLabel>{t("form.title")}</FormLabel>
          <RadioGroup
            name="spacing"
            aria-label="spacing"
            onChange={updateData("title")}
            row
            required
          >
            {["mrs", "mr", "ms", "miss"].map(value => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio />}
                label={t(`form.${value}`)}
              />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            onChange={updateData("email")}
            fullWidth
            label={t("form.email")}
            required
            type="email"
            value={formData.email}
            autoComplete={"off"}
            error={isWrongEmailFormat}
            helperText={isWrongEmailFormat && t("form.error.email")}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          style={{ display: "flex", alignItems: "baseline" }}
        >
          <TextField
            disabled={currentTime > 0 || formData.isOtpValid || loading.send}
            onChange={updateData("mobile")}
            fullWidth
            pattern="\d*"
            label={t("form.mobile")}
            maxLength={8}
            type="tel"
            required
            autoComplete={"off"}
            value={formData.mobile}
          />
          <StyledButton
            disabled={
              !formData.mobile ||
              currentTime > 0 ||
              formData.isOtpValid ||
              formData.mobile.length < 8 ||
              loading.send
            }
            onClick={handleOtpRequest}
            size="small"
          >
            {loading.send && (
              <CircularProgress
                variant="indeterminate"
                size={20}
                thickness={4}
                style={{ color: "#f27060", marginRight: "5px" }}
              />
            )}
            {currentTime > 0
              ? `${currentTime}s`
              : formData.isOtpSent
              ? t("form.resend")
              : t("form.send")}
          </StyledButton>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          style={{ display: "flex", alignItems: "baseline" }}
        >
          <TextField
            fullWidth
            disabled={
              !formData.mobile ||
              !formData.isOtpSent ||
              formData.isOtpValid ||
              loading.verify
            }
            label={t("form.otp")}
            onChange={updateData("otp")}
            maxLength={6}
            type="tel"
            required
            autoComplete={"off"}
            value={formData.otp}
            InputProps={{
              endAdornment: formData.isOtpValid && (
                <InputAdornment position="end">
                  <CheckCircleIcon style={{ color: "#fff" }} />
                </InputAdornment>
              ),
            }}
          />
          {!formData.isOtpValid && (
            <StyledButton
              disabled={
                !formData.otp ||
                formData.isOtpValid ||
                formData.otp.length !== 6 ||
                loading.verify
              }
              onClick={handleOtpVerify}
              size="small"
            >
              {loading.verify && (
                <CircularProgress
                  variant="indeterminate"
                  size={20}
                  thickness={4}
                  style={{ color: "#f27060", marginRight: "5px" }}
                />
              )}
              {formData.isOtpValid ? t("form.verified") : t("form.verify")}
            </StyledButton>
          )}
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                required
                onChange={updateData("agree1")}
                checked={formData.agree1}
              />
            }
            label={<div style={{ fontSize: "12px" }}>{t("form.agree1")}*</div>}
          />
          <div style={{ marginLeft: "31px" }}>
            <div style={{ fontSize: "12px" }}>
              <FileLink target="_blank" href="https://yas.com.hk/legal-info/">
                {t("form.tnc1")}
              </FileLink>
            </div>
            <div style={{ fontSize: "12px" }}>
              <FileLink
                target="_blank"
                href={
                  lang === "en"
                    ? "https://yas.com.hk/declaration-en/"
                    : "https://yas.com.hk/declaration-zh_hk/"
                }
              >
                {t("form.tnc2")}
              </FileLink>
            </div>
            <div style={{ fontSize: "12px" }}>
              <FileLink
                target="_blank"
                href={
                  lang === "en"
                    ? "https://www.generali.com.hk/personal-information-collection-statement"
                    : "https://www.generali.com.hk/ZH_HK/personal-information-collection-statement"
                }
              >
                {t("form.tnc3")}
              </FileLink>
            </div>
          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          {process.env.GATSBY_DM_OPT_OUT === "true" ? (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={updateData("agree2")}
                  checked={!formData.agree2}
                />
              }
              label={
                <div style={{ fontSize: "12px" }}>{t("form.agree2-alt")}</div>
              }
            />
          ) : (
            <>
              <FormLabel style={{ fontSize: "12px" }}>
                {t("form.agree2")}*
              </FormLabel>
              <RadioGroup
                name="spacing"
                aria-label="spacing"
                onChange={updateData("agree2")}
                row
              >
                {["yes", "no"].map(value => (
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<Radio required />}
                    label={t(`form.${value}`)}
                  />
                ))}
              </RadioGroup>
            </>
          )}
        </Grid>
        <Grid item md={6} xs={12} style={{ paddingTop: 30 }}>
          <Button
            disabled={!isAllowedToSubmit() || loading.submit}
            fullWidth
            size="large"
            type="submit"
          >
            {loading.submit && (
              <CircularProgress
                variant="indeterminate"
                size={20}
                thickness={4}
                style={{ color: "#f27060", marginRight: "5px" }}
              />
            )}
            {t("form.submit")}
          </Button>
        </Grid>
        <Grid item md={12} xs={12} style={{ paddingTop: "0px" }}>
          <Tnc4>
            <Trans i18nKey="form.tnc4">
              <FileLink target="_blank" href="https://bravo.generali.com.hk/" />
            </Trans>
          </Tnc4>
        </Grid>
      </Grid>
      <Modal
        open={false || hasError}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBody>
          <StyledCancelIcon onClick={handleModalClose} />
          <ModalHead>
            <StyledWarningIcon />
            <div>{error}</div>
          </ModalHead>
          <ModalFooter>{t("error.retry")}</ModalFooter>
        </ModalBody>
      </Modal>
    </SubmissionFormWrapper>
  )
}

const StyledCancelIcon = styled(CancelIcon)`
  position: absolute;
  font-size: 30px;
  top: -10px;
  right: -10px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
  cursor: pointer;
`

const StyledWarningIcon = styled(WarningIcon)`
  font-size: 100px;
  @media only screen and (max-width: 768px) {
    & {
      font-size: 100px;
    }
  }
`

const ModalBody = styled.div`
  max-width: 320px;
  height: 200px;
  top: calc(50% - 160px);
  position: relative;
  right: 10%;
  left: 10%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin: 0 auto;
  position: fixed;
  outline: none;
  user-select: none;
  @media only screen and (max-width: 768px) {
    & {
      max-width: 320px;
      height: 200px;
      top: calc(50% - 100px);
    }
  }
`

const ModalHead = styled.div`
  flex: 3;
  background-color: #ea5648;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  font-size: 20px;
  @media only screen and (max-width: 768px) {
    & {
      font-size: 16px;
    }
  }
`

const ModalFooter = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  @media only screen and (max-width: 768px) {
    & {
      font-size: 16px;
    }
  }
`

const Tnc4 = styled.div`
  fontsize: 10px;
  width: 1000px;
  @media only screen and (max-width: 1000px) {
    & {
      width: auto;
    }
  }
`

const SubmissionFormWrapper = styled.form``

const StyledButton = styled(Button)`
  margin-left: 10px;
`

export default SubmissionForm
