import React from "react"

import { useTranslation, Trans } from "react-i18next"
import styled from "styled-components"

import SubmissionForm from "../components/submissionForm"

import mobileBackground from "../images/bg-mobile.png"
import FileLink from "../components/fileLink"
import SEO from "../components/seo"
import Share from "../components/share"

const IndexPage = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language || "zh"

  return (
    <>
      <SEO title={t("app.title")} />
      <Content>
        <XXXXXX>
          <Topic lang={lang}>{t("main.topic")}</Topic>
          <Topic2>{t("main.topic2")}</Topic2>
          <AlertMessage>{t("main.message")}</AlertMessage>
        </XXXXXX>
        <YYYYY>
          <Share />
          <Styledul>
            <Styledli>{t("main.point1")}</Styledli>
            <Styledli>{t("main.point2")}</Styledli>
            <Styledli>{t("main.point4")}</Styledli>
          </Styledul>
          <TopicHelper>
            <Trans i18nKey="main.helper">
              <FileLink
                target="_blank"
                href="/coronavirus-coverage-details.pdf"
              />
              <FileLink
                target="_blank"
                href="/coronavirus-privacy-provisions.pdf"
              />
            </Trans>
          </TopicHelper>
          <StyledSubmissionForm lang={lang} />
        </YYYYY>
      </Content>
    </>
  )
}

const TopicHelper = styled.div`
  font-size: 14px;
  margin-bottom: 30px;
  @media only screen and (max-width: 768px) {
    & {
      font-size: 12px;
    }
  }
`

const Topic = styled.div`
  font-size: 60px;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 50px;
    }
  }
  @media only screen and (max-width: 600px) {
    & {
      font-size: ${props => (props.lang === "en" ? "34px" : "40px")};
    }
  }
`

const Topic2 = styled.div`
  font-size: 18px;
  margin-bottom: 30px;
  white-space: pre-line;
  @media only screen and (max-width: 600px) {
    & {
      font-size: 14px;
    }
  }
`

const Styledul = styled.ul`
  margin-left: 0px;
  padding-left: 15px;
  // list-style-position: inside;
`

const Styledli = styled.li`
  list-style-type: none;
  position: relative;
  font-weight: bold;
  font-size: 20px;

  &:before {
    content: "·";
    position: absolute;
    left: -15px; /* Adjust this value so that it appears where you want. */
    font-size: 40px;
    line-height: 25px;
  }

  @media only screen and (max-width: 768px) {
    & {
      font-size: 16px;
    }
  }
`

const AlertMessage = styled.div`
  font-weight: bold;
  font-size: 20px;

  @media only screen and (max-width: 768px) {
    & {
      font-size: 16px;
    }
  }
`

const XXXXXX = styled.div`
  @media only screen and (max-width: 768px) {
    & {
      background-image: url(${mobileBackground});
      background-size: contain;
      background-position-x: center;
      background-position-y: bottom;
      background-repeat: no-repeat;
      padding: 25px 16px 0px;
      height: calc(100vh - 110px);
    }
  }

  @media (max-width: 768px) and (orientation: landscape) {
    & {
      height: calc(100vw * 1.1);
    }
  }

  @media (max-width: 600px) and (orientation: portrait) {
    & {
      height: calc(100vh * 1.1);
      max-height: 600px;
    }
  }
`

const YYYYY = styled.div`
  @media only screen and (max-width: 768px) {
    & {
      padding: 0 16px;
    }
  }
`

const Content = styled.div`
  margin: 0px auto;
  padding: 50px 30px 30px;
  max-width: 1360px;
  @media only screen and (max-width: 768px) {
    & {
      padding: 0px;
      padding-bottom: 30px;
    }
  }
`

const StyledSubmissionForm = styled(SubmissionForm)`
  width: ${props => (props.lang === "en" ? "570px" : "500px")};
  @media only screen and (max-width: 768px) {
    & {
      width: 100%;
    }
  }
`

export default IndexPage
