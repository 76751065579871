import { useEffect, useRef, useState } from "react"

const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  // 保存新回调
  useEffect(() => {
    savedCallback.current = callback
  })

  // 建立 interval
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const useCountDown = time => {
  const [currentTime, setCurrentTime] = useState(0)

  useInterval(
    () => {
      const nextCurrnet = currentTime - 1
      setCurrentTime(nextCurrnet)
    },
    currentTime > 0 ? 1000 : null
  )

  const resetCountDown = () => {
    setCurrentTime(time)
  }

  const clearCountDown = () => {
    setCurrentTime(0)
  }

  return { currentTime, resetCountDown, clearCountDown }
}

export const useError = err => {
  const [error, setError] = useState(err)

  const clearError = () => {
    setError(null)
  }

  return { error, hasError: !!error, setError, clearError }
}
